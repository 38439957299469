import React from 'react'
import "./Map.css";

function Map() {
  return (
    <div className="google-map-code">
    <iframe
    title='Google Maps'
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1942.3684525708218!2d80.30842968412455!3d13.17898139579737!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52702614924353%3A0xfda05d72b4c26a08!2sAnnai%20Srirangam%20New%20Town!5e0!3m2!1sen!2sin!4v1713933980030!5m2!1sen!2sin" 
        width="600"
        height="450"
        frameborder="0"
        style={{ border: 0 }}
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
    />
</div>
  )
}

export default Map