import { motion } from 'framer-motion';
import './Services.css';
import Stats from './Stats';
import serviceCard from "./service.png";

const services = [
  {
    title: "Design",
    items: ["UI Design", "UX Design", "UX Consultancy", "Design System", "Animation", "Illustrations"],
    color: "#FF6B6B"
  },
  {
    title: "Technology",
    items: ["Web Design", "Softwares", "Mobile Apps", "Web Apps", "Front-End", "Back-End"],
    color: "#5CDB95"
  },
  {
    title: "Business",
    items: ["Branding", "Brand Name", "Brand Guidelines", "Strategy", "Digital Marketing", "S.E.O"],
    color: "#6A9EF6"
  }
];

const Services = () => {
  return (
    <>
    <div className="about-content">
           <div className="text-container">
          <span>OUR SERVICE</span>
          <h2>We provide best business solutions</h2>
          <p>
          At Quixent, we specialize in providing the finest business solutions tailored to your needs. Our dedicated team excels in every aspect of mobile and web application design services, ensuring that you receive the highest quality solutions for your business.Our team handles the full cycle of mobile and web application design services. From conceptualization to development and deployment, we cover every stage with precision and expertise. By understanding your unique requirements, we craft customized solutions that align perfectly with your business objectives.
            The Team handles the full cylce of mobile and web apps design services. We produce only top-notch applications with great design UI, UX, Navigation and Functionality. User experience is paramount in today's competitive market. We prioritize usability and accessibility to ensure that your application provides a seamless experience for every user. By conducting thorough user testing and optimization, we refine your application to perfection, maximizing user satisfaction and retention.</p>
            {/* <div className='service-div'>
      <button className="button primary-button">Get Started</button>
      <button className="button secondary-button">Learn More</button>
    </div> */}
        </div>
          <img src={serviceCard} alt='services' />
        </div>
        <div className="services-header-container">
      <span className="services-header-title">ALL SERVICES</span>
      <h2 className="services-header-subtitle">See all our services</h2>
    </div>
    <div className="services-container">
      {services.map((service, index) => (
        <motion.div
          key={index}
          className="service"
          style={{ backgroundColor: service.color }}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: index * 0.1 }}
        >
          <h2>{service.title}</h2>
          <ul>
            {service.items.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
        </motion.div>
      ))}
    </div>
    <Stats />
    </>
  );
}

export default Services;
