import React from 'react';
import './Header.css';
// import logo_blue_aqua from "./logo_blue_aqua.png";

const Header = () => {
  return (
    <header className="header">
      {/* <div className="logo-container">
        <img src={logo_blue_aqua} alt="logo" className="logo" />
      </div> */}
        <nav className="nav-menu">
          <a href="#home" className="nav-item">Home</a>
          <a href="#about" className="nav-item">About</a>
          <a href="#services" className="nav-item">Services</a>
          <a href="#contact" className="nav-item">Contact</a>
        </nav>
    </header>
  );
};

export default Header;
