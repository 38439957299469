import React from 'react';
import { motion } from 'framer-motion';
import './Hero.css';
import logo_blue_aqua from "./logo_blue_aqua.png";

function Hero() {
  return (
    <motion.div
      className="hero-container"
      initial={{ opacity: 0, x: -100 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 1 }}
    >
      <div className="hero-content">
        <div className="image-container">
          <img src={logo_blue_aqua} alt="" />
          {/* <img src="https://technicmentors.com/assets/img/web-development-services.gif" alt="" /> */}
        </div>
        <div className="text-container hero-text-container">
            <h1>Web & Mobile Development</h1>
          <p>
          Welcome to Quixent, where we specialize in crafting cutting-edge mobile and web applications that drive transformation and innovation for businesses like yours.
          Every business has its own challenges and objectives. At Quixent, we understand this, which is why we offer tailored mobile and web app development solutions designed specifically for your unique requirements. Whether you're a startup looking to disrupt the market or an established enterprise aiming to streamline operations, we have the expertise to deliver results that align perfectly with your goals.
          Mobile and Web App development solution for transforming and innovating businesses.
            </p>
        </div>
      </div>
    </motion.div>
  );
}

export default Hero;
